
import { Component, Inject, Vue, Watch } from "vue-property-decorator";
import { Container } from "inversify";
import MoreActions from "../../../molecules/components/MoreActions.vue";
import { Api } from "../../../../../common/src/api/Api";
import { NotificationStore } from "../../../../../common/src/stores/NotificationStore";
import { handleError } from "../../../../../common/src/utility/errorUtils";
import LoadingOverlay from "../../../../../common/src/components/LoadingOverlay.vue";
import { MergerAcquisitionsListStore } from "../../../stores/admin/mergerAcquisitions/MergerAcquisitionsListStore";
import NoRecords from "../../../../../common/src/components/NoRecords.vue";
import { debounce } from "debounce";
import { AuthStore } from "../../../../../common/src/stores/AuthStore";

@Component({
    components: { MoreActions, LoadingOverlay, NoRecords },
})
export default class MergerAcquisitionsListview extends Vue {
    @Inject("container") readonly container!: Container;
    readonly authStore: AuthStore = this.container.get(AuthStore);
    readonly listStore: MergerAcquisitionsListStore = this.container.get(
        MergerAcquisitionsListStore,
    );
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);
    readonly api: Api = this.container.get(Api);

    @Watch("listStore.query")
    reloadDebounce = debounce(this.reload, 300);

    mounted() {
        this.listStore.load().catch(handleError);
    }

    reload() {
        this.listStore.reload().catch(handleError);
    }

    getOrganizationUrl(orgId: string | undefined) {
        if (orgId) {
            return `/admin/organizations/${orgId}`;
        } else {
            return "";
        }
    }

    getMergeAcquisitionEditUrl(mergerAcqId: string) {
        return `/admin/mergerAcquisitions/${mergerAcqId}`;
    }

    async destory(mergerAcqId: string) {
        try {
            var confirmation = confirm("Are you sure you want to delete Merger/Acquisition");
            if (confirmation) {
                await this.api.admin.mergerAcquisition.delete(mergerAcqId);
                this.notificationStore.success("User has been deleted.");
                this.listStore.reload().catch(handleError);
            }
        } catch (e) {
            this.notificationStore.warning("Failure deleting Merger/Acquisition news.");
        }
    }
}
